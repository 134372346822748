import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import useLanguage from './hooks/useLanguage.js';
import './assets/style.css';

// Create the Vue app and use the router and i18n instance
const app = createApp(App);

// Use useLanguage custom hook
const { i18n, setLanguage } = useLanguage();

// Add a method to the app's global properties for changing language
app.config.globalProperties.$setLanguage = setLanguage;
history.scrollRestoration = 'manual';

app.use(router);
app.use(i18n);
app.mount('#app');