const messages = {
    en: {
        message: {
            HOME: 'Home',
            DARK_MODE: 'Dark Mode',
            LIGHT_MODE: 'Light Mode',
            PROJECT: 'Project',
            PROFILE: 'Profile',
            EN: 'en',
            CN: 'cn',
            GARY_HERE: 'Gary here...',
            GARY_HERE_TEXT: 'Please search for my projects below!',
            CODINGSEAL: 'Front-end Developer',
            CODINGSEAL_DESCRIPTION: 'I am a passionate front-end developer that has successfully built over 20 websites with multiple tools, mainly with Javascript and its framework, Vue3.',
            CALISTHENICSEAL: 'Calisthenic Enthusiast',
            CALISTHENICSEAL_DESCRIPTION: 'Calisthenic is a lifestyle for me. I am currently training hard to become an advanced calisthenic athlete.',
            BOXINGSEAL: 'Beginner Boxer',
            BOXINGSEAL_DESCRIPTION: 'I attend weekly boxing lesson to empower both my body and spirit.',
            JAPANESESEAL: 'Japanese Learner',
            JAPANESESEAL_DESCRIPTION: 'I have started taking weekly Japanese lessons this year to enhance my cultural connection and language proficiency.',
            EATINGSEAL: 'Big Eater',
            EATINGSEAL_DESCRIPTION: 'I am a big eater that can consume up to 5 bowls of rice in a meal! My favourite food is white rice.',
        }
    },
    cn: {
        message: {
            HOME: '首页',
            DARK_MODE: '深色模式',
            LIGHT_MODE: '浅色模式',
            PROJECT: '项目',
            PROFILE: '个人资料',
            EN: '英语',
            CN: '中文',
            GARY_HERE: '我是 Gary...',
            GARY_HERE_TEXT: '请协助我寻找我以下的项目',
            CODINGSEAL: '前端开发人员',
            CODINGSEAL_DESCRIPTION: '我是一名充满热情的前端开发者，成功地使用多种工具构建了超过20个网站，主要使用JavaScript及其框架Vue3。',
            CALISTHENICSEAL: '自重健身爱好者',
            CALISTHENICSEAL_DESCRIPTION: '自重健身对我来说是一种生活方式。我目前正在努力训练，以成为一名自重健身运动员。',
            BOXINGSEAL: '拳击新手',
            BOXINGSEAL_DESCRIPTION: '我每周参加拳击课我每周参加拳击课程，以强化我的身体和精神。',
            JAPANESESEAL: '日语学生',
            JAPANESESEAL_DESCRIPTION: '我刚开始上日文课，以增强我对日本文化的认知和语言水平。',
            EATINGSEAL: '大胃王',
            EATINGSEAL_DESCRIPTION: '我是一个大胃王，一顿饭能吃完多达5碗米饭！我最喜欢的食物是白米饭。',

        }
    }
}

export default messages