export const routes = [
    {
        path: '/',
        component: () => import('../layout/Layout.vue'),
        children: [
            {
                path: '/',
                name: '/',
                component: () => import('../views/Home.vue')
            },

            {
                path: '/profile',
                name: 'profile',
                component: () => import('../views/Profile.vue')
            },
            {
                path: '/project',
                name: 'project',
                component: () => import('../views/Project.vue')
            },
        ]
    },
]

export default routes;
