import { createI18n } from 'vue-i18n';
import messages from '../locales';

const useLanguage = () => {

    // Create a VueI18n instance
    const i18n = createI18n({
        legacy: false,
        locale: localStorage.getItem('locale') || 'en', // Sets language as value stored in local storage or default en
        fallbackLocale: 'en',
        messages,
    });

    function setLanguage(language) {
        localStorage.setItem('locale', language);
        i18n.global.locale.value = language;
    }

    return { i18n, setLanguage };
};

export default useLanguage;
